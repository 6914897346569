import { createClient } from "~/supabase";
import { CompanyData, Result } from "~/common-types";
import { retry } from "~/backoff";

export const getCompanyData = retry(
  async (
    getCompanyId: string,
    supabase: ReturnType<typeof createClient>,
  ): Promise<Result<CompanyData>> => {
    console.log("API: getCompanyData", getCompanyId);

    const { data, error } = await supabase
      .from("companies")
      .select(
        "show_payed_button_on_sender, show_temporary_notification_popups_on_sender",
      )
      .eq("id", getCompanyId)
      .single();
    if (error) {
      console.error(error);
      return { ok: false, error: error.message };
    } else {
      if (data) {
        return { ok: true, value: data };
      } else {
        return { ok: false, error: "No company data" };
      }
    }
  },
  3,
);
