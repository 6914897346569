import { Switch as KoSwitch } from "@kobalte/core";
import { Component } from "solid-js";

import styles from "./Switch.module.css";

type SwitchProps = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const Switch: Component<SwitchProps> = (props) => {
  return (
    <KoSwitch.Root
      class={`${styles.container}`}
      checked={props.checked}
      onChange={props.onChange}
    >
      <KoSwitch.Label class={`${styles.label}`}>{props.label}</KoSwitch.Label>
      <KoSwitch.Input class={`${styles.input}`} />
      <KoSwitch.Control class={`${styles.control}`}>
        <KoSwitch.Thumb class={`${styles.thumb}`} />
      </KoSwitch.Control>
    </KoSwitch.Root>
  );
};

export default Switch;
