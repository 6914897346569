import { Component, For, Show, createSignal, createEffect } from "solid-js";
import { IoAddCircle, IoRemoveCircle } from "solid-icons/io";
import Dialog from "~/components/Dialog";
import IconButton from "~/components/IconButton";
import AddToBill from "./AddToBill";
import styles from "./Order.module.css";
import { Course } from "~/common-types";

type OrderItem = {
  id: string;
  description: string;
  price: number;
  quantity: number;
  course_id?: string;
};

const NO_GROUP = "NO_GROUP";

const Order: Component<{
  items: OrderItem[];
  onOrderQuantityChange: (id: string, quantity: number) => void;
  show: boolean;
  setShow: (v: boolean) => void;
  table: string;
  sendOrder: () => void;
  count: number;
  totalPrice: string;
  courses?: Course[];
  isSubmitting?: boolean;
}> = (props) => {
  const [selectedItemId, setSelectedItemId] = createSignal<string | null>(null);
  let pressTimer: number | undefined;

  // Effetto per resettare selectedItemId quando il componente viene aperto
  createEffect(() => {
    if (props.show) {
      setSelectedItemId(null);
    }
  });

  const handleItemClick = (id: string) => {
    setSelectedItemId(selectedItemId() === id ? null : id);
  };

  const groupByCourse: () => Map<string, OrderItem[]> = () => {
    if (!props.courses) return new Map([[NO_GROUP, props.items]]);

    const courseMap = [...props.courses]
      .sort((a, b) => a.order_id - b.order_id)
      .reduce<Map<string, Course>>((acc, course) => {
        acc.set(course.id, course);
        return acc;
      }, new Map());

    const initial = new Map([[NO_GROUP, []]]);
    for (const course of courseMap.values()) {
      initial.set(course.name, []);
    }

    const grouped = props.items.reduce<Map<string, OrderItem[]>>(
      (acc, item) => {
        const courseName = item.course_id
          ? courseMap.get(item.course_id)!.name
          : NO_GROUP;

        const current = acc.get(courseName)!;
        acc.set(courseName, [...current, item]);
        return acc;
      },
      initial,
    );

    // move NO_GROUP to the end
    const noGroup = grouped.get(NO_GROUP);
    grouped.delete(NO_GROUP);
    grouped.set(NO_GROUP, noGroup!);

    //console.log(grouped);

    return grouped;
  };

  const numberOfGroupsWithItems = () =>
    props.courses && props.courses.length > 0
      ? Array.from(groupByCourse().keys()).filter(
          (courseName) =>
            courseName !== NO_GROUP &&
            groupByCourse().get(courseName)!.length > 0,
        ).length
      : 0;

  // Funzione di utilità per dividere la descrizione in parti
  const renderDescription = (description: string) => {
    const parts = description.split("\n");
    return (
      <div class={styles.descriptionContainer}>
        <div class={styles.inlineContainer}>
          {parts.slice(0, 2).map((part, index) => {
            let className = index === 0 ? styles.namePart : styles.formatPart;
            return <div class={className}>{part}</div>;
          })}
        </div>
        {parts.slice(2).map((part, index) => {
          let className =
            index === 0 ? styles.specificationsPart : styles.commentPart;
          return <div class={className}>{part}</div>;
        })}
      </div>
    );
  };

  return (
    <Dialog open={props.show} setOpen={props.setShow} title="Comanda">
      <div class={styles.order}>
        <div class={styles.orderItems}>
          <For each={Array.from(groupByCourse().keys())}>
            {(courseName) => (
              <>
                <Show
                  when={
                    courseName !== NO_GROUP &&
                    groupByCourse().get(courseName)!.length > 0
                  }
                >
                  <h3 class={styles.courseName}>{courseName}</h3>
                </Show>
                <Show
                  when={
                    (props.courses?.length ?? 0) > 0 &&
                    courseName === NO_GROUP &&
                    groupByCourse().get(courseName)!.length > 0 &&
                    numberOfGroupsWithItems() > 0
                  }
                >
                  <div class={styles.separator} role="separator" />
                </Show>
                <For
                  each={groupByCourse().get(courseName)}
                  fallback={
                    <Show
                      when={
                        courseName === NO_GROUP &&
                        numberOfGroupsWithItems() === 0 &&
                        groupByCourse().get(courseName)!.length === 0
                      }
                    >
                      <div class={styles.orderInfo}>
                        La comanda e' vuota al momento
                      </div>
                    </Show>
                  }
                >
                  {(item) => (
                    <>
                      <div class={styles.orderItemContainer}>
                        <Show when={courseName !== NO_GROUP}>
                          <div class={styles.orderLineGrouper}></div>
                        </Show>
                        <article
                          class={`${styles.orderItem} ${
                            selectedItemId() === item.id ? styles.selected : ""
                          }`}
                          onClick={() => handleItemClick(item.id)}
                        >
                          <div class={styles.quantity}>{item.quantity}</div>
                          <div
                            class={`${styles.name} ${
                              item.quantity === 0 ? styles.strike : ""
                            }`}
                          >
                            {renderDescription(item.description)}
                          </div>
                          <div class={styles.price}>
                            {(item.price * item.quantity).toLocaleString(
                              "it-IT",
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              },
                            )}{" "}
                            €
                          </div>
                          {selectedItemId() === item.id && (
                            <div class={styles.actions}>
                              <IconButton
                                disabled={item.quantity <= 0}
                                onClick={() =>
                                  props.onOrderQuantityChange(
                                    item.id,
                                    item.quantity - 1,
                                  )
                                }
                              >
                                <IoRemoveCircle />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  props.onOrderQuantityChange(
                                    item.id,
                                    item.quantity + 1,
                                  )
                                }
                              >
                                <IoAddCircle />
                              </IconButton>
                            </div>
                          )}
                        </article>
                      </div>
                    </>
                  )}
                </For>
              </>
            )}
          </For>
        </div>
        <AddToBill
          sendOrder={props.sendOrder}
          count={props.count}
          totalPrice={props.totalPrice}
          isSubmitting={props.isSubmitting}
        />
      </div>
    </Dialog>
  );
};

export default Order;
