import { Component, Show } from "solid-js";
import styles from "./AddToBill.module.css";
import Button from "~/components/Button";
import { FaSolidSpinner } from "solid-icons/fa";

const AddToBill: Component<{
  sendOrder: () => void;
  count: number;
  totalPrice: string;
  isSubmitting?: boolean;
}> = (props) => {
  return (
    <section
      classList={{
        [styles.addToBill]: true,
        [styles.submitting]: props.isSubmitting,
      }}
    >
      <Button
        onClick={props.sendOrder}
        disabled={props.count === 0 || props.isSubmitting}
        variant="primary"
      >
        <div class={styles.elements}>
          <span class={styles.count}>{props.count}</span>
          <Show
            when={!props.isSubmitting}
            fallback={
              <FaSolidSpinner role="spinbutton" class={styles.hourglass} />
            }
          >
            <span>Aggiungi al Conto</span>
          </Show>
          <span>
            {Number(props.totalPrice).toLocaleString("it-IT", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{" "}
            €
          </span>
        </div>
      </Button>
    </section>
  );
};

export default AddToBill;
