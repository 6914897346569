import { Meta, Title } from "@solidjs/meta";
import {
  action,
  useAction,
  useNavigate,
  useParams,
  useSubmission,
} from "@solidjs/router";
import { FaSolidCashRegister, FaSolidClipboardList } from "solid-icons/fa";
import {
  Component,
  createMemo,
  createResource,
  createSignal,
  Show,
} from "solid-js";
import { createStore, produce, reconcile } from "solid-js/store";
import { OrderItem } from "~/common-types";
import AlertDialog from "~/components/AlertDialog";
import Bill from "~/components/Bill";
import Dialog from "~/components/Dialog";
import IconButton from "~/components/IconButton";
import Main from "~/components/Main";
import PickProducts from "~/components/PickProducts";
import InputSwitch from "~/components/Switch";
import AddToBill from "~/components/table_name/AddToBill";
import Order from "~/components/table_name/Order";
import { showToast } from "~/components/Toast";
import { getCompanyData } from "~/server/companies";
import { getCourses } from "~/server/courses";
import { getOrderByTableName, newOrder } from "~/server/orders";
import { getProducts } from "~/server/products";
import { createClient, getCompanyId } from "~/supabase";
import { createResourceStorage } from "~/utils/create-resource-storage";
import styles from "./[table_name].module.css";

export const route = {};

const TableName: Component = () => {
  const urlParams = useParams();
  const tableName = decodeURIComponent(urlParams.table_name);
  const navigate = useNavigate();

  const products = createResourceStorage("products", getProducts);
  const courses = createResourceStorage("courses", getCourses);

  const [orders, { refetch: refetchOrders }] = createResource(() =>
    getOrderByTableName(tableName),
  );

  const [company] = createResource(
    async (): ReturnType<typeof getCompanyData> => {
      const supabase = createClient();
      const getCompanyIdResult = await getCompanyId(supabase);
      if (getCompanyIdResult.ok === false) {
        return { ok: false, error: getCompanyIdResult.error };
      }
      if (getCompanyIdResult.value === "") {
        return { ok: false, error: "No company data" };
      }
      return getCompanyData(getCompanyIdResult.value, supabase);
    },
  );

  /* State */
  const [orderItemsMap, setOrderItemsMap] = createStore<{
    [id: string]: OrderItem;
  }>({});
  const [showOrder, setShowOrder] = createSignal(false);
  const [isConfirmSendOrderAlertVisible, setIsConfirmSendOrderAlertVisible] =
    createSignal(false);
  const [isBillVisible, setIsBillVisible] = createSignal(false);
  const [orderFlags, setOrderFlags] = createStore({
    shouldPrint: true,
    shouldPay: true,
  });

  /* Computed */
  const orderItems = createMemo(() => Object.values(orderItemsMap));

  const totalItems = () =>
    orderItems().reduce((acc, item) => acc + item.quantity, 0);

  const total = () =>
    orderItems().reduce((acc, item) => acc + item.price * item.quantity, 0);

  const showConfirmSendOrderAlert = () => {
    const comp = company();
    return Boolean(
      comp?.ok &&
        comp.value.show_payed_button_on_sender &&
        isConfirmSendOrderAlertVisible(),
    );
  };

  /* Actions */
  const sendOrderAction = action(
    async (params: { shouldPay: boolean; shouldPrint: boolean }) => {
      const result = await newOrder({
        tableName,
        orderItems: Object.values(orderItemsMap),
        orderState: "TO_PREPARE",
        paid: !params.shouldPay,
        toPrint: params.shouldPrint,
      });

      if (result.ok) {
        setOrderItemsMap(reconcile({}));
        showToast({ message: "Ordine aggiunto al conto" });
        refetchOrders();
      } else {
        showToast({ message: "Errore nell'invio dell'ordine" });
      }
    },
  );

  const sendOrder = useAction(sendOrderAction);
  const sendingOrder = useSubmission(sendOrderAction);

  return (
    <>
      <Title>Sender</Title>
      <Meta name="description" content="Create Order" />
      <Main
        title={tableName}
        backLink="/app/sender/pick-table"
        rightElement={
          <div class={styles.menuActions}>
            <IconButton onClick={() => setShowOrder(true)}>
              <FaSolidClipboardList />
              <Show when={totalItems() > 0}>
                <span class={styles.actionsNumber}>{totalItems()}</span>
              </Show>
            </IconButton>
            <IconButton onClick={() => setIsBillVisible(true)}>
              <FaSolidCashRegister />
            </IconButton>
          </div>
        }
      >
        <Show
          when={products.state === "success" && courses.state === "success"}
        >
          <PickProducts
            products={() =>
              products.state === "success" ? products.data : undefined
            }
            courses={() =>
              courses.state === "success" ? courses.data : undefined
            }
            tableName={tableName}
            orderItemsMap={orderItemsMap}
            setOrderItemsMap={setOrderItemsMap}
          />
        </Show>
        <Show when={totalItems() > 0}>
          <div class={styles.addToBill}>
            <AddToBill
              sendOrder={() => {
                const comp = company();
                if (
                  Boolean(comp?.ok && comp.value.show_payed_button_on_sender)
                ) {
                  setIsConfirmSendOrderAlertVisible(true);
                } else {
                  setOrderFlags({ shouldPay: true, shouldPrint: true });
                  sendOrder(orderFlags);
                }
              }}
              isSubmitting={sendingOrder.pending}
              count={totalItems()}
              totalPrice={total().toFixed(2)}
            />
          </div>
        </Show>
      </Main>
      <Order
        show={showOrder()}
        setShow={setShowOrder}
        table={tableName}
        items={orderItems()}
        courses={courses.state === "success" ? courses.data : undefined}
        onOrderQuantityChange={(id, quantity) => {
          setOrderItemsMap(
            produce((orderItems) => {
              if (orderItems[id] != null) {
                orderItems[id].quantity = Math.max(quantity, 0);
              }
              if (orderItems[id]?.quantity === 0) {
                delete orderItems[id];
              }
            }),
          );
        }}
        sendOrder={() => {
          const comp = company();
          if (Boolean(comp?.ok && comp.value.show_payed_button_on_sender)) {
            setIsConfirmSendOrderAlertVisible(true);
          } else {
            setOrderFlags({ shouldPay: true, shouldPrint: true });
            sendOrder(orderFlags);
            setShowOrder(false);
          }
        }}
        isSubmitting={sendingOrder.pending}
        count={totalItems()}
        totalPrice={total().toFixed(2)}
      />
      <Dialog open={isBillVisible()} setOpen={setIsBillVisible} title="CONTO">
        <Bill
          table={tableName}
          orders={orders() ?? []}
          afterVariationInvalidation={["orders"]}
          afterVariation={() => {
            setIsBillVisible(false);
            navigate(`/app/sender/pick-table`);
          }}
          showHeader={false}
        />
      </Dialog>
      <AlertDialog
        open={showConfirmSendOrderAlert()}
        setOpen={setIsConfirmSendOrderAlertVisible}
        title="Conferma"
        showCancelButton={false}
        onCancel={() => {
          setIsConfirmSendOrderAlertVisible(false);
          sendOrder(orderFlags);
          setShowOrder(false);
          setOrderFlags({ shouldPay: true, shouldPrint: true });
          showToast({ message: "Ordine non inviato a Bar/Cucina" });
        }}
        onConfirm={() => {
          setIsConfirmSendOrderAlertVisible(false);
          sendOrder(orderFlags);
          setShowOrder(false);
          setOrderFlags({ shouldPay: true, shouldPrint: true });
          showToast({ message: "Ordine inviato a Bar/Cucina" });
        }}
      >
        <section class={styles.alertDialog}>
          <InputSwitch
            label="Ordine da pagare?"
            checked={orderFlags.shouldPay}
            onChange={(checked) => {
              setOrderFlags("shouldPay", checked);
            }}
          />
          <InputSwitch
            label="Stampare l'ordine?"
            checked={orderFlags.shouldPrint}
            onChange={(checked) => {
              setOrderFlags("shouldPrint", checked);
            }}
          />
        </section>
      </AlertDialog>
    </>
  );
};

export default TableName;
