import { createStore } from "solid-js/store";

type ResourceFetcher<T> =
  | {
      state: "loading";
    }
  | {
      state: "error";
      error: Error;
    }
  | {
      state: "success";
      data: T;
    };

export function createResourceStorage<T>(key: string, fn: () => Promise<T>) {
  const [store, setStore] = createStore<ResourceFetcher<T>>({
    state: "loading",
  });
  const localResult = localStorage.getItem(key);

  if (localResult) {
    try {
      const parsedResult = JSON.parse(localResult);
      setStore({
        state: "success",
        data: parsedResult.data,
      });
    } catch (e) {
      console.error("Error parsing local storage", e);
    }
  }

  fn()
    .then((data) => {
      localStorage.setItem(key, JSON.stringify({ data }));
      setStore({
        state: "success",
        data,
      });
    })
    .catch((error) => {
      setStore({ state: "error", error });
    });

  return store;
}
